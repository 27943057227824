// src/index.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Home';
import OCDTools from './OCDTools';
import TOS from './TOS';
import Privacy from './Privacy';
import Donate from './Donate';
import Dashboard from './Dashboard';
import Settings from './Settings';
import SUDSImport from './SUDSImport';
import OCDNews from './OCDNews';
import OCDTriggerTracker from './OCDTriggerTracker';
import MoodTracker from './MoodTracker';
import ExposureHierarchyBuilder from './ExposureHierarchyBuilder';



function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/tools" element={<OCDTools />} />
        <Route path="/sudsimport" element={<SUDSImport />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/ocdnews" element={<OCDNews />} />
        <Route path="/trigger" element={<OCDTriggerTracker />} />
        <Route path="/mood" element={<MoodTracker />} />
        <Route path="/ExposureHierarchyBuilder" element={<ExposureHierarchyBuilder />} />
      </Routes>
    </Router>

    </>
  );
}

export default App;
