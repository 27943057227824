import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAJfBhWP8-Ll-o3C3ivNDJ12e32pJTeTDI',
  authDomain: 'ocd-serenity.firebaseapp.com',
  projectId: 'ocd-serenity',
  storageBucket: 'ocd-serenity.appspot.com',
  messagingSenderId: '876045176792',
  appId: '1:876045176792:web:e29ab52bb6eac629dc6cad',
  measurementId: 'G-CTPS6J3GJN',
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
