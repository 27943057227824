import React, { useState, useEffect } from 'react';
import './MoodTracker.css';
import Navbar from './Navbar';
import Warning from './Warning';
import Footer from './Footer';

const MoodTracker = () => {
  const [moodData, setMoodData] = useState(() => {
    const savedData = localStorage.getItem('moodTracker');
    return savedData ? JSON.parse(savedData) : {};
  });

  useEffect(() => {
    localStorage.setItem('moodTracker', JSON.stringify(moodData));
  }, [moodData]);

  // Toggle mood for a given day
  const handleClick = (month, day) => {
    const key = `${month}-${day}`;
    setMoodData(prevData => {
      const currentMood = prevData[key] || 0;
      const newMood = (currentMood % 4) + 1; // Cycle through 3 moods (1, 2, 3)
      return {
        ...prevData,
        [key]: newMood,
      };
    });
  };

  // Clear all mood data from localStorage
  const handleClearData = () => {
    if (
      window.confirm(
        'Are you sure you want to clear all your mood tracking data?'
      )
    ) {
      localStorage.removeItem('moodTracker');
      setMoodData({}); // Reset mood data state
      alert('Your mood data has been cleared.');
    }
  };

  // Emoji map for moods
  const moodEmojis = {
    1: '😊',
    2: '🙂',
    3: '😟',
    4: '😫',
  };

  // Months to display (12 months)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Render the grid with days and months
  const renderGrid = () => {
    return months.map((month, monthIndex) => (
      <div key={month} className='month-row'>
        {/* Month label */}
        <div className='month-label'>{month}</div>
        <div className='days-row'>
          {Array.from({ length: 31 }, (_, day) => (
            <div
              key={day + 1}
              className={`grid-cell mood-${
                moodData[`${monthIndex}-${day + 1}`] || 0
              }`} // Apply mood class
              onClick={() => handleClick(monthIndex, day + 1)}
            >
              {moodEmojis[moodData[`${monthIndex}-${day + 1}`]] || ''}
            </div>
          ))}
        </div>
      </div>
    ));
  };

  return (
    <>
      <Navbar />
      <div className='container-mood'>
        <Warning />
        <h1>Pixel Mood Tracker</h1>
        <p>
          The <strong>Pixel Mood Tracker</strong> is a simple and interactive
          tool to help you track your daily mood throughout the year. Each row
          represents a month, and each square represents a day of that month. To
          record your mood, simply click on the square corresponding to the day.
          You can cycle through three different moods by clicking the square
          multiple times:
        </p>
        <ul>
          <li>
            😊 <strong>No distress</strong> (green),
          </li>
          <li>
            🙂 <strong>Minimal distress</strong> (yellow),
          </li>
          <li>
            😟 <strong>Quite distressed</strong> (blue),
          </li>
          <li>
            😫 <strong>Extremely distressed</strong> (red).
          </li>
        </ul>
        <p>
          The tool automatically saves your entries to local storage, so your
          data will be available even after refreshing the page or closing the
          browser. You can visually track patterns in your mood over time by
          observing the color variations on the calendar grid.
        </p>

        {/* Calendar Grid */}
        <div className='calendar-grid'>{renderGrid()}</div>
        {/* Clear Data Button */}
        <button className='clear-btn' onClick={handleClearData}>
          Clear All Data
        </button>
      </div>
      <Footer />
    </>
  );
};

export default MoodTracker;
