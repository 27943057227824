// SUDSImport.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SUDSImport.css';
import Navbar from './Navbar';
import Warning from './Warning';
import Footer from './Footer';

const SUDSImport = () => {
  const [timeLeft] = useState(0);
  const [intervalId] = useState(null);
  const [timeInterval, setTimeInterval] = useState(5); // New state for time interval
  const [importedAnxietyLevels, setImportedAnxietyLevels] = useState('');
  const [importedInterval, setImportedInterval] = useState('5'); // Default to 5 min
  const [importedRitual, setImportedRitual] = useState('');

  console.log(timeInterval);

  // Function to load settings from localStorage
  const loadSettings = () => {
    const savedSettings = JSON.parse(localStorage.getItem('settings'));
    if (savedSettings && savedSettings.interval) {
      setTimeInterval(savedSettings.interval);
    }
  };

  const fearTriggers = [
    'Arranging items symmetrically',
    'Avoiding cracks on sidewalks',
    'Avoiding unlucky numbers (e.g., 13)',
    'Cleaning excessively to remove germs',
    'Checking appliances (e.g., stove, oven) repeatedly',
    'Checking door locks multiple times',
    'Counting objects in surroundings (e.g., tiles, cars)',
    'Counting steps while walking',
    'Organizing items by color or size',
    'Placing objects in a specific order (e.g., books, utensils)',
    'Re-arranging clothing or accessories until perfect',
    'Re-checking alarm clock settings multiple times',
    'Re-reading or re-writing text for errors',
    'Repeating actions until they feel "right"',
    'Repeating prayers or mantras for "protection"',
    'Seeking constant reassurance from others',
    'Touching objects a specific number of times',
    'Turning lights on and off repeatedly',
    'Washing hands repeatedly',
  ];

  const storedRituals =
    JSON.parse(localStorage.getItem('rituals')) || fearTriggers;

  const [rituals] = useState(storedRituals);

  // Save rituals to localStorage whenever the list changes
  useEffect(() => {
    localStorage.setItem('rituals', JSON.stringify(rituals));
  }, [rituals]);

  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate('/dashboard');
  };

  const handleImportSubmit = () => {
    // Parse the anxiety levels from the input
    const levelsArray = importedAnxietyLevels
      .split(',')
      .map(level => parseInt(level.trim(), 10))
      .filter(level => !isNaN(level));

    if (importedRitual && levelsArray.length > 0) {
      const storedData = JSON.parse(localStorage.getItem(importedRitual)) || [];

      // Create new anxiety level objects based on the selected interval
      const newLevels = levelsArray.map((level, index) => ({
        time: index * parseInt(importedInterval, 10),
        level: level,
      }));

      const updatedData = [...storedData, newLevels];
      localStorage.setItem(importedRitual, JSON.stringify(updatedData));

      alert('Data imported successfully.');
      setImportedAnxietyLevels(''); // Clear the input
      goToDashboard();
    } else {
      alert('Please provide valid data and select a ritual.');
    }
  };

  useEffect(() => {
    loadSettings(); // Load settings on component mount
  }, []);

  useEffect(() => {
    if (timeLeft === 0 && intervalId) {
      clearInterval(intervalId);
    }
  }, [timeLeft, intervalId]);

  // Save rituals to localStorage whenever the list changes
  useEffect(() => {
    localStorage.setItem('rituals', JSON.stringify(rituals));
  }, [rituals]);

  return (
    <>
      <Navbar />
      <div className='import'>
        <Warning />
        <h1>SUDS Import</h1>
        <div className='import-header'>
          <p>
            Use this feature to quickly log multiple anxiety levels from your
            exposure exercises. Simply enter your levels of anxiety (SUDS
            scores) separated by commas, and select the interval at which these
            values were recorded. You can also choose the ritual practiced
            during the exercise.
          </p>
        </div>
        <p>
          Input your anxiety levels as comma-separated values (e.g., 85, 70,
          62).
        </p>

        <textarea
          value={importedAnxietyLevels}
          onChange={e => setImportedAnxietyLevels(e.target.value)}
          placeholder='Enter anxiety levels (e.g., 85, 70, 62)'
          rows='4'
        ></textarea>

        <div className='select-row'>
          <div className='select-container'>
            <label>
              Select Interval:
              <br />
              <br />
              <select
                value={importedInterval}
                onChange={e => setImportedInterval(e.target.value)}
              >
                <option value='3'>3 minutes</option>
                <option value='5'>5 minutes</option>
                <option value='10'>10 minutes</option>
                <option value='15'>15 minutes</option>
                <option value='20'>20 minutes</option>
              </select>
            </label>
          </div>

          <div className='select-container'>
            <label>
              Select Ritual:
              <br />
              <i>(Go to the Settings to customize your list</i>)
              <select
                value={importedRitual}
                onChange={e => setImportedRitual(e.target.value)}
              >
                <option value='' disabled>
                  Select a ritual
                </option>

                {rituals.map((ritual, index) => (
                  <option key={index} value={ritual}>
                    {ritual}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <div className='button-container'>
          <button onClick={handleImportSubmit} className='import-button'>
            Import Data
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SUDSImport;
