import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import CSS for styling

function Navbar() {
  return (
    <nav className='navbar'>
      <div className='logo'>OCD Serenity</div>
      <div className='navbar-text'>
        <ul className='nav-links'>
          <li>
            <Link to='/'>🏡 Home</Link>
          </li>
          <li>
            <Link to='/ocdnews'>🫂 OCD News Feed</Link>
          </li>
          <li className='menu-item'>
            <span>🛠️ Tools</span>
            <ul className='submenu'>
              <li>
                <Link to='/trigger'>🎯 Trigger Tracker</Link>
              </li>
              <li>
                <Link to='/mood'>😊 Mood Tracker</Link>
              </li>
              <li>
                <Link to='/ExposureHierarchyBuilder'>
                  🚀 Exposure Hierarchy Builder
                </Link>
              </li>
              <li>
                <Link to='/tools'>⏱️ SUDS Tracker</Link>
              </li>
              <li>
                <Link to='/sudsimport'>📥 SUDS Import</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/dashboard'>📊 Dashboard</Link>
          </li>
          <li>
            <Link to='/settings'>⚙️ Settings</Link>
          </li>
          <li>
            <Link to='/donate'>❤️ Donate</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
