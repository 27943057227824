import React, { useEffect, useState } from 'react';
import './OCDNews.css';
import Navbar from './Navbar';
import Footer from './Footer';

const OCDNews = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const articlesPerPage = 15; // Articles per page

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(
          'https://www.ocdserenity.com/news-feed.json'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch news');
        }
        const data = await response.json();
        setArticles(data.articles);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  const formatDate = dateString => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
  };

  const filteredArticles = articles.filter(article => {
    const title = article.title.toLowerCase();
    const description = article.description.toLowerCase();
    const search = searchTerm.toLowerCase();
    return title.includes(search) || description.includes(search);
  });

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const highlightText = text => {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        <span key={index} style={{ backgroundColor: 'black', color: 'white' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  if (loading) {
    return <p>Loading news...</p>;
  }

  if (error) {
    return <p>Error loading news: {error}</p>;
  }

  return (
    <>
      <Navbar />

      <div className='ocdnews'>
        <div className='search-bar'>
          <input
            type='text'
            placeholder="SEARCH e.g. CBT OR 'obsessive thoughts'"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>
        <div className='ocdnews-main-content'>
          <div className='content-main'>
            <div className='ocdnews-content'>
              <p>
                Our OCD News Feed keeps you informed with the latest research,
                treatments, and support resources for OCD. Curated from trusted
                sources, this feed provides up-to-date articles and insights to
                help you stay connected with the OCD community and learn more
                about strategies, therapies, and developments that can make a
                difference in managing OCD. Whether you're looking for
                scientific advancements or practical advice, our news feed is
                here to support your journey.
              </p>
              {currentArticles.map((article, index) => (
                <div className='news-item' key={index}>
                  <span className='source'>{article.source.name}</span>
                  <br />
                  <span className='time'>
                    {formatDate(article.publishedAt)}
                  </span>
                  <br />
                  <a
                    href={article.url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className='news-title'>
                      🔗 {highlightText(article.title)}
                    </span>
                  </a>
                  <br />
                  <span className='data'>
                    {highlightText(article.description)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Pagination */}
        <div className='pagination'>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default OCDNews;
